import Head from "next/head";
import { useRouter } from "next/router";
import { SellerPageRouter } from "../../modules/seller/page-router.props";
import { IconArrowLeft } from "../icons/icon-arrow-left";

export const ErrorFallback: React.FC<{
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}> = ({ error }) => {
  const router: SellerPageRouter = useRouter();
  return (
    <>
      <Head>
        <title>Terjadi Kesalahan</title>
      </Head>
      <div className="mx-auto size-full sm:max-w-screen-mobile bg-white flex flex-col min-h-screen">
        <header className="fixed max-w-screen-mobile w-full flex items-center p-4 bg-white z-10">
          <a href={`/${router.query.seller}`}>
            <IconArrowLeft
              className="cursor-pointer z-10"
              id="header-back-btn"
              data-testid="header-back-btn"
            />
          </a>
        </header>
        <div className="flex flex-col justify-center items-center p-4 h-screen">
          <div className="text-lg font-bold mb-2">Terjadi Kesalahan</div>
          <span className="font-medium">{error?.message}</span>
        </div>
      </div>
    </>
  );
};
